.Gang-Top{
    background-color: #321c4b;
    display: inline-flex;
    align-items: center;
    text-align: center;
    width: 100%;
}

.Loggedin-Gang-Top-content-container{
    padding-top: 2rem;
    padding-bottom: 2rem;
    margin:auto;
}

.LoggedIn-top-image-container img{

}

.pfp{
    width: 10vw;
    border-radius: 50%;
}

.Gang-Text-Title{
    font-size: 72px;
    font-weight: 700;
    padding-top: 2rem;
}

.Gang-Text-Subtitle{
    font-size: 28px;
    padding-bottom: 2rem;
}


@media (max-width: 1000px) {
    .Gang-Text-Title{
        font-size: 10vw;
    }

    .pfp{
        width: 30vw;
        border-radius: 50%;
    }

    .Gang-Text-Subtitle{
        font-size: 6vw;
    }
}