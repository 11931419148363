.comic-zone{
    background-image: url('./cyko-ko-cover-7.png');
    background-attachment: fixed;
    background-size: cover;
    margin: 0;
}


.overlay-comic{
    text-align: center;
    background-color: rgba(0, 0, 0, 0.8);
    min-height: 95vh;

}

.comic-container{
    display: block;
    text-align: center;
    margin: auto;
}

.comic-box{
    text-align: center;
    margin-top: 20px;
    margin-left: auto;
    margin-right: auto;
    width: 50vw;
    min-width: 300px;
}

.close-button{
    bottom: 2%;
    position: fixed;
    background-color: #e43f3f;
    color: #ffffff ;
    box-shadow: 0 2px 2px 0 rgb(0 0 0 / 20%);
    padding: 0.75rem 0.75rem;
    border-radius: 6px;
    font-weight: 600;
    border-width: 1px;
    transition: all 0.2s ease-in-out;
    font-size: calc( 1.0350000000000001rem + (1.1 - 1.0350000000000001) * ((100vw - 20rem) / (48 - 20)));
    line-height: calc( 1.4 * (1.0350000000000001rem + (1.1 - 1.0350000000000001) * ((100vw - 20rem) / (48 - 20))));
    margin:auto;
    text-align: center;
}

.close-button:hover{
    background-color: black;
}
