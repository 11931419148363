.pong-main {
    text-align: center;
    margin: auto;
    min-height: 100vh;
    background-color: black;
    align-items: center;
}

.testing-container {
    max-width: 95vw;
    width: auto;
    height: auto;
    margin: auto;
}