.Gang-Top{
    background-color: #321c4b;
    display: inline-flex;
    align-items: center;
    text-align: center;
    width: 100%;
}

.Gang-Top-content-container{
    padding-top: 4rem;
    padding-bottom: 4rem;
    margin:auto;
}

.top-image-container img{
    width: 50%;
}

.Gang-Text-Container{
    color: white;
    text-align: center;
}

.Gang-Text-Title{
    font-size: 72px;
    font-weight: 700;
    padding-top: 2rem;
}

.Gang-Text-Subtitle{
    font-size: 28px;
    padding-bottom: 2rem;
}

.Gang-Text-Container button{
    color: 'black' !important;
    background-color: #66ff00 !important;
    border-color: #66ff00 !important;
    box-shadow: 0 2px 5px 0 rgb(0 0 0 / 20%);
    padding: 0.75rem 1.5rem;
    font-size: 18px;
    border-radius: 5pt;
    font-weight: 800;
    border: none;
}

.Gang-Text-Container button:hover{
    color: #ffffff !important;
    background-color: #43a800 !important;
    border-color: #43a800 !important;
    box-shadow: 0 2px 5px 0 rgb(0 0 0 / 20%);
    transition: ease 0.5s;
}



.Gang-Second{
    background-color: #44e43f;
    display: inline-flex;
    align-items: center;
    text-align: center;
    width: 100%;
}


.Gang-second-content{
    margin:auto;
}

.Gang-second-text-container{
    color: rgb(0,0,0);
    padding-top: 5rem;
    padding-bottom: 2rem;
}

.Gang-second-text-container h1{
    font-size: 64px;
    font-weight: 800;
    
}

.Gang-second-text-container h3{
    font-size: 24px;
    
}

.Gang-reward-contanier{
    display: inline-flex;
    justify-content: space-between;
}

@media (max-width: 1000px) {
    .Gang-reward-contanier{
        display: grid;
    }
}

.Gang-reward{
    padding-left: 1rem;
    padding-right: 1rem;
    padding-bottom: 5rem;
}

.gang-reward-text-container{
    text-align: left;
    background-color: #ff48c4;
    width: 20vw;
    justify-content:space-between;
    font-size: 18px;
    padding: 2rem;
    margin: auto;
    min-height: 40vh;
    height: auto;
}

@media (max-width: 1000px) {
    .gang-reward-text-container{
        width: 20vw;
        height: auto;
        font-size: 18px;
        padding: 2rem;
        width: 80%;
    }
}


.gang-reward-text-container button{
    color: 'black' !important;
    background-color: #66ff00 !important;
    border-color: #66ff00 !important;
    box-shadow: 0 2px 5px 0 rgb(0 0 0 / 20%);
    padding: 0.75rem 1.5rem;
    font-size: 18px;
    border-radius: 5pt;
    font-weight: 800;
    border: none;
}

.gang-reward-text-container button:hover{
    color: #ffffff !important;
    background-color: #43a800 !important;
    border-color: #43a800 !important;
    box-shadow: 0 2px 5px 0 rgb(0 0 0 / 20%);
    transition: ease 0.5s;
}



.gang-reward-img-container img{
    width: 20vw;
    text-align: center;
}

@media (max-width: 1000px) {
    .gang-reward-img-container img{
        width: 80%;
    }
}

.discord{
    padding: 8vh;
    background-color: #2bd1fc;
    text-align: center;
    margin: auto;
    width: 100%;
}

.discord h1{
    color: black;
    font-weight: 900;
    font-size: 4rem;
}

.discord img{
    width: 10vw;
    height: auto;
}

.discord-button{
    background-color: #321c4b;
    color: white;
    width: 40vw;
    font-size: 3rem;
    font-weight: 800;
}


.monthly-main-container{
   background-color: #ff48c4;
   text-align: center;
}

.monthly-main-container img{
    margin-top: 50px;
    margin-bottom: 50px;
    width: 60vw;
}