.Footer-main{
    background-color: #321c4b;
    display: inline-block;
    width:100%;
    color: white;
    margin: auto;
    text-align: center;
}

.Footer-main-text{
    text-align: center;
    align-items: center;
    font-size: 18px;
    padding-top: 1rem;
}

.Footer-main p{
    font-size: 8px;
}