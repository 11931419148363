.pong-main{
    text-align: center;
    margin: auto;
    min-height: 100vh;
    background-color: black;
    align-items: center;
}

.pong-main button{
    background-color: #44e43f;
    font-weight: 600;
}

.pong-container{
    margin: auto;
    max-width:95vw;
    width: auto;
    height:auto;
}

.pong-game{
    height: 100%;
    width: 100%;
}