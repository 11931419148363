.discord-redirect-main{
  min-height: 100vh;
  min-width: 100vw;
  text-align: center;
  background-color: #2bd1fc;
}

.discord-redirect-main-home-button{
  background-color: #321c4b;
  color: white;
  font-size: 3rem;
  font-weight: 500;
  width: 30vw;
  min-width: fit-content;
  border-radius: 10px;
}

.discord-redirect-main img{
  padding-top: 20px;
  width: 30vw;
}

.discord-redirect-main h1{
  font-weight: 800;
}